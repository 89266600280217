import { graphql } from "gatsby";
import Html2ReactParser from "html-to-react/lib/parser";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/global/breadcrumbs";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";
import InternalNavWidget from "../components/widgets/internalNavWidget";
import RelatedArticlesWidget from "../components/widgets/relatedArticlesWidget";

// CSS
import "../styles.css"

const PolicyTemplate = ( props ) => {
   useEffect(() => {
      window.scrollTo({ top: 0 })
   }, [])

   // Variables
   const page = props.data.contentfulPolicy;
   const word_count = page.content.internal.content.split(" ").length + 1;
   const reading_time = (word_count/250).toFixed(0)

   const generateContent = () => {
      const content = page.content.internal.content;
      const htmlToReactParser = new Html2ReactParser();
      const reactElement = htmlToReactParser.parse(content)

      return reactElement
   }

   return (
      <React.Fragment>
         <SEO
            title={page.title}
            slug={page.slug}
            metaDescription={page.metaDescription.internal.content}
            image=""
            titleTemplate="%s - Appliance Sure"
         />

         <Navigation/>

         <div className="content-hero-container">
            <div className="content-hero-content-container">
               <h1>{page.title}</h1>
               <p>{page.articleDescription.internal.content}</p>
            </div>
         </div>

         <div className="page-details-container">
            <div className="page-details-inner-container narrow-width">
               <p className="reading-time"><strong>Reading time</strong> - {reading_time} {reading_time === '1' ? 'minute' : 'minutes'}</p>
               <p className="last-updated"><strong>Last updated</strong> - {new Date(page.updatedAt).toLocaleDateString("en-GB")}</p>
            </div>
         </div>

         <div className="standard-container content">
            <div className="content-split-container narrow-width">
               <div className="content-container">
                  <Breadcrumbs
                     location={page.slug}
                  />

                  {generateContent()}
               </div>
               <div className="side-bar-container">
                  <div className="side-bar-entries">
                     <InternalNavWidget navigation={page.navigation.internal.content}/>

                     <RelatedArticlesWidget topic="policies"/>
                  </div>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default PolicyTemplate

export const pageQuery = graphql`
   query pageBySlug( $slug: String! ) {
      contentfulPolicy ( slug: { eq: $slug }) {
         metaDescription {
            internal {
               content
            }
         }
         title
         slug
         updatedAt
         content {
            internal {
               content
            }
         }
         articleDescription {
            internal {
               content
            }
         }
         navigation {
            internal {
               content
            }
         }
      }
   }
`