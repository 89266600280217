import React from "react";

const RelatedArticlesWidget = ({ topic }) => {
   const generateOptions = () => {
      switch (topic) {
         case "policies":
            return [
               ["Privacy policy", "/privacy-policy"],
               ["Cookies policy", "/cookies-policy"],
               ["Terms of use", "/terms-of-use"],
               ["Terms & conditions", "/terms-conditions"],
               ["Complaints policy", "/complaints-policy"],
               ["Cancellation policy", "/cancellation-policy"]
            ]
      }
   }
   return (
      <span>
         <input
            type="checkbox"
            defaultChecked={true}
            id="related-articles"
            className="related-articles-trigger invisible"
         />

         <label className="related-articles-label" htmlFor="related-articles">Related pages</label>

         <div className="related-articles-content">
            {generateOptions().map(option => (
               <a href={option[1]}>{option[0]}</a>
            ))}
         </div>
      </span>
   )
}

export default RelatedArticlesWidget